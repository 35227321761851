import Vue from 'vue'
import Vuex from 'vuex'
import AppStore from './AppStore'
import UserStore from './UserStore'
import NotificationStore from './NotificationStore'
import FulfilmentStatusCountsStore from './FulfilmentStatusCountsStore'
import FacilityStore from './FacilityStore'
import PractitionerStore from './PractitionerStore'
import OrganisationStore from './OrganisationStore'
import DomainsStore from './DomainsStore'
import SearchStore from './SearchStore'
import FulfilmentSearchStore from './FulfilmentSearchStore'
import LanguageStore from '@/store/LanguageStore.js'
import EthnicityStore from '@/store/EthnicityStore.js'
import AddressFormStore from '../../../Common/store/AddressFormStore'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    AppStore,
    UserStore,
    NotificationStore,
    FulfilmentStatusCountsStore,
    FacilityStore,
    PractitionerStore,
    OrganisationStore,
    SearchStore,
    FulfilmentSearchStore,
    DomainsStore,
    LanguageStore,
    EthnicityStore,
    AddressFormStore
  }
})
