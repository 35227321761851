export default {
    namespaced: true,

    state: {
        section: {
            expandManualUkAddress: false,
            expandManualUsAddress: false,
            expandManualQAAddress: false,
            expandManualAEAddress: false,
        },
        autocomplete: {
            addressQueryString: null,
            autocompleteSuggestions: [],
            addressTimeout: null
        },
        address: {
            language: null,
            buildingNumber: null,
            buildingName: null,
            street: null,
            line: null,
            line2: null,
            line3: null,
            district: null,
            city: null,
            provinceName: null,
            provinceCode: null,
            state: null,
            postalCode: null,
            country: null,
            countryCode: null,
            countryIso3Code: null,
            latitude: 0,
            longitude: 0
        },
        addressErrors: {}
    },

    getters: {
        GetAddress (state) {
            return state.address
        },

        GetSections (state) {
            return state.section
        },

        GetAutocomplete (state) {
            return state.autocomplete
        },

        GetAddressErrors (state) {
            return state.addressErrors
        }
    },

    mutations: {
        SET_ADDRESS(state, data) {
            state.address = data
        },

        SET_COUNTRY_CODE(state, data) {
            state.address.countryCode = data
        },

        CLEAR_ADDRESS(state) {
            state.address.language = null
            state.address.buildingNumber = null
            state.address.buildingName = null
            state.address.street = null
            state.address.line = null
            state.address.line2 = null
            state.address.line3 = null
            state.address.district = null
            state.address.city = null
            state.address.provinceName = null
            state.address.provinceCode = null
            state.address.state = null
            state.address.postalCode = null
            state.address.country = null
            state.address.countryIso3Code = null
            state.address.latitude = 0
            state.address.longitude = 0
        },

        CLEAR_ADDRESS_ERRORS(state) {
            state.addressErrors = {}
        },

        CLEAR_STATE(state) {
            state.section = {
                expandManualUkAddress: false,
                expandManualUsAddress: false,
                expandManualQAAddress: false,
                expandManualAEAddress: false,
            },
            state.autocomplete = {
                addressQueryString: null,
                autocompleteSuggestions: [],
                addressTimeout: null
            },
            state.address = {
                language: null,
                buildingNumber: null,
                buildingName: null,
                street: null,
                line: null,
                line2: null,
                line3: null,
                district: null,
                city: null,
                provinceName: null,
                provinceCode: null,
                state: null,
                postalCode: null,
                country: null,
                countryCode: null,
                countryIso3Code: null,
                latitude: 0,
                longitude: 0
            },
            state.addressErrors = {}
        }
    },

    actions: {
        SetAddress({commit}, data) {
            commit('SET_ADDRESS', data)
        },

        SetCountryCode({commit}, data) {
            commit('SET_COUNTRY_CODE', data)
        },

        ClearAddress({commit}, _) {
            commit('CLEAR_ADDRESS')
        },

        ClearAddressErrors({commit}) {
            commit('CLEAR_ADDRESS_ERRORS')
        },

        ClearState({commit}) {
            commit('CLEAR_STATE')
        }
    }
}