import { mapGetters } from 'vuex'
import CancellationReasonType from '@/enums/CancellationReasonType'

require('vue-moment')
export default {
    data () {
        return {
            CancellationReasonType
        }
    },
    computed: {
      ...mapGetters({
        languages: 'LanguageStore/GetLanguages'
      })
    },
    methods: {
        formatDate (value) {
            if (value == null) return 'N/A'
            if (value === '0001-01-01T00:00:00') return 'Not issued'
            return this.$moment(value).format('DD/MM/YYYY')
        },

        formatDateTime (value) {
            if (value == null) return 'N/A'
            if (value === '0001-01-01T00:00:00') return 'N/A'
            return this.$moment(value).format('DD/MM/YYYY HH:mm:ss')
        },

        formatTitle (value) {
            if (value === 0) return 'Mr'
            if (value === 1) return 'Mrs'
            if (value === 2) return 'Miss'
            if (value === 3) return 'Ms'
            if (value === 4) return 'Dr'
            if (value === 5) return 'Sir'
            if (value === 6) return 'Professor'

            return ''
        },

        convertNullToInt (val) {
            if (val === null) return 0; return val
        },

        formatDistanceInMiles (value) {
            return `${value.toFixed(2)} miles`
        },

        formatAddress (value, forEncoding = false) {
            let addressString = ''

            if (value == null) {
                return addressString
            }

            let seperator = ', '
            if (forEncoding) {
                seperator = ' '
            }

            if (value.poBoxNumber) addressString += `PO Box ${value.poBoxNumber}${seperator}`
            if (value.line) addressString += `${value.line}${seperator}`
            if (value.line2) addressString += `${value.line2}${seperator}`
            if (value.line3) addressString += `${value.line3}${seperator}`
            if (value.city) addressString += `${value.city}${seperator}`
            if (value.provinceName) addressString += `${value.provinceName}${seperator}`
            if (value.postalCode) addressString += `${value.postalCode}${seperator}`
            if (value.country) addressString += `${value.country}${seperator}`
            if (value.countryCode) addressString += `${value.countryCode}`

            return addressString
        },

        formatShippingAddress (value) {
            let addressString = ''
            const seperator = ', '

            if (value == null) {
                return addressString
            }

            if (value.poBoxNumber) addressString += `PO Box ${value.poBoxNumber}${seperator}`
            if (value.line) addressString += `${value.line}${seperator}`
            if (value.line2) addressString += `${value.line2}${seperator}`
            if (value.line3) addressString += `${value.line3}${seperator}`
            if (value.city) addressString += `${value.city}${seperator}`
            if (value.provinceName) addressString += `${value.provinceName}${seperator}`
            if (value.state) addressString += `${value.state}${seperator}`
            if (value.postalCode) addressString += `${value.postalCode}${seperator}`
            if (value.country) addressString += `${value.country}${seperator}`
            if (value.countryCode) addressString += `${value.countryCode}`

            return addressString
        },

        formatCollectionPointAddress (value) {
            let addressString = ''

            if (value == null) {
                return addressString
            }

            const seperator = ', '

            if (value.line) addressString += `${value.line}${seperator}`
            if (value.city) addressString += `${value.city}${seperator}`
            if (value.provinceName) addressString += `${value.provinceName}${seperator}`
            if (value.state) addressString += `${value.state}${seperator}`
            if (value.postalCode) addressString += `${value.postalCode}${seperator}`
            if (value.country) addressString += `${value.country}`

            return addressString
        },

        formatSerialNumber (value) {
            if (!value) return 'Not issued'

            return value
        },

        formatLanguageCode (value) {
            if (!value) return ''
            return value.displayId + '-' + value.code // + '-' + value.name
        },

        formatGA (value) {
            // lmp = latest menstrual period
            // ga = gestational age

            var current = new Date()
            var lmp = new Date(value)
            lmp.setDate(lmp.getDate() - 280)

            if (lmp > current) return null

            const diff = Math.abs(current - lmp)

            const gaInDays = Math.floor(diff / (1000 * 60 * 60 * 24))
            var gaWeeks = Math.floor(gaInDays / 7)

            return gaWeeks
        },

        formatLanguageLabel (code) {
            if (code == null) return null
            const found = this.languages.find(x => x.code === code)
            if (!found) return 'Unknown'
            return found.name
        },

        formatCancelledReasonType (type) {
            if (type === CancellationReasonType.TEST_COMPLETED_IN_CLINIC) return 'Test completed in clinic'
            if (type === CancellationReasonType.BABY_DELIVERED) return 'Baby delivered'
            if (type === CancellationReasonType.MISCARRIAGE_STILLBIRTH) return 'Miscarriage / stillbirth'
            if (type === CancellationReasonType.DUPLICATE) return 'Duplicate test'
            if (type === CancellationReasonType.PATIENT_MOVED) return 'Patient moved hospital'
            if (type === CancellationReasonType.INCOMPLETE_FAULTY_TEST) return 'Incomplete / faulty test kit'
            if (type === CancellationReasonType.VOMITED) return 'Vomited'
            if (type === CancellationReasonType.SPILLED_THE_DRINK) return 'Spilled the drink'
            if (type === CancellationReasonType.USED_ALL_LANCETS) return 'Used all the lancets'
            if (type === CancellationReasonType.UPLOAD_FAILED) return 'Results had not uploaded successfully'
            if (type === CancellationReasonType.DELIVERED_INCORRECT_ADDRESS) return 'Test delivered to incorrect address'
            if (type === CancellationReasonType.TEST_DECLINED) return 'Delicined to do the test'
            if (type === CancellationReasonType.STARTED_LATE) return 'Started late'
            if (type === CancellationReasonType.USER_ERROR) return 'User error'
            if (type === CancellationReasonType.HCP_ERROR) return 'HCP error'
            if (type === CancellationReasonType.NEEDLE_PHOBIC) return 'Needle phobic'
            if (type === CancellationReasonType.OTHER) return 'Other'
            return 'Unknown'
        }
    }
}
