import axios from 'axios'
export default {
    namespaced: true,

    state: {
        ethnicities: []
    },

    getters: {
        GetEthnicities (state) {
            return state.ethnicities
        }
    },

    mutations: {
        SET_ETHNICITIES (state, data) {
            state.ethnicities = data
        }
    },

    actions: {
        async GetEthnicities ({ commit }) {
            try {
                const response = await axios.get(`${process.env.VUE_APP_AXIOS_PATIENT_BASE_URL}/api/v1/ethnicities`)

                commit('SET_ETHNICITIES', response.data.primaryEthnicityDefinitions)
            } catch (err) {
                throw new Error(err)
            }
        }
    }
}
