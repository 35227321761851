import axios from 'axios'
export default {
    namespaced: true,

    state: {
        languages: [],
        organisationDefaults: []
    },

    getters: {
        GetLanguages (state) {
            return state.languages
        },

        GetOrganisationDefaults (state) {
            return state.organisationDefaults
        }
    },

    mutations: {
        PUSH_LANGUAGES (state, data) {
            state.languages = data
        },

        PUSH_ORGANISATION_DEFAULTS (state, data) {
            state.organisationDefaults = data
        }
    },

    actions: {
        async PopulateLanguages ({ commit, state }) {
            try {
                if (state.languages && state.languages.length > 0) {
                    return state.languages
                }

                if (state.organisationDefaults && state.organisationDefaults.length > 0) {
                    return state.organisationDefaults
                }

                const languages = await axios.get(`${process.env.VUE_APP_AXIOS_DIAGNOSTIC_BASE_URL}/api/v1/languages`)
                const organisationDefaults = await axios.get(`${process.env.VUE_APP_AXIOS_ORGANISATION_BASE_URL}/api/v1/languages/OrganisationDefaultLanguages`)

                commit('PUSH_LANGUAGES', languages.data)
                commit('PUSH_ORGANISATION_DEFAULTS', organisationDefaults.data)
            } catch (err) {
                throw new Error(err)
            }
        }
    }
}
