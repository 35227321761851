const CancellationReasonType = Object.freeze({
    TEST_COMPLETED_IN_CLINIC: 0,
    BABY_DELIVERED: 1,
    MISCARRIAGE_STILLBIRTH: 2,
    DUPLICATE: 3,
    PATIENT_MOVED: 4,
    INCOMPLETE_FAULTY_TEST: 5,
    VOMITED: 6,
    SPILLED_THE_DRINK: 7,
    USED_ALL_LANCETS: 8,
    UPLOAD_FAILED: 9,
    DELIVERED_INCORRECT_ADDRESS: 10,
    TEST_DECLINED: 11,
    STARTED_LATE: 12,
    USER_ERROR: 13,
    HCP_ERROR: 14,
    NEEDLE_PHOBIC: 15,
    OTHER: 16
})

export default CancellationReasonType
