import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'
import MiddlewarePipeline from './kernel/MiddlewarePipeline'
import UserMustBeOwner from './middleware/UserMustBeOwner'
import UserMustBeAdminOrOwner from './middleware/UserMustBeAdminOrOwner'
import RedirectIfUserIsFulfilment from './middleware/RedirectIfUserIsFulfilment'
import UserMustBeDomainAdmin from './middleware/UserMustBeDomainAdmin'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'Error404',
    component: () => import('../views/Error404.vue')
  },
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
      middleware: [
        RedirectIfUserIsFulfilment
      ]
    }
  },
  {
    path: '/my-account',
    name: 'MyAccount',
    component: () => import('../views/MyAccount.vue'),
    meta: {
      breadcrumbTitle: 'My Account'
    }
  },
  {
    path: '/domains',
    name: 'Domains',
    component: () => import('../views/Domains.vue'),
    meta: {
      middleware: [
        UserMustBeDomainAdmin
      ]
    }
  },
  {
    path: '/organisations',
    name: 'Organisations',
    component: () => import('../views/Organisations.vue'),
    meta: {
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  },
  {
    path: '/organisations/create',
    name: 'CreateOrganisation',
    component: () => import('../views/CreateOrganisation.vue'),
    meta: {
      breadcrumbTitle: 'Add an organisation',
      breadcrumbs: [
        {
          title: 'Organisations',
          link: 'Organisations'
        }
      ],
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  },
  {
    path: '/organisations/:id',
    name: 'ViewOrganisation',
    component: () => import('../views/ViewOrganisation.vue'),
    meta: {
      middleware: [
        UserMustBeAdminOrOwner
      ],
      breadcrumbs: [
        {
          title: 'Organisations',
          link: 'Organisations'
        }
      ]
    }
  },
  {
    path: '/organisations/:id/create-facility',
    name: 'CreateFacility',
    component: () => import('../views/CreateFacility.vue'),
    props: true,
    meta: {
      breadcrumbTitle: 'Add a facility',
      breadcrumbs: [
        {
          title: 'Organisations',
          link: 'Organisations'
        }
      ],
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  },
  {
    path: '/facilities/:oId/:fId',
    name: 'ViewFacility',
    component: () => import('../views/ViewFacility.vue'),
    meta: {
      breadcrumbs: [
        {
          title: 'Organisations',
          link: 'Organisations'
        }
      ],
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  },
  {
    path: '/facilities/:oId/:fId/edit',
    name: 'EditFacility',
    component: () => import('../views/EditFacility.vue'),
    breadcrumbs: [
      {
        title: 'Organisations',
        link: 'Organisations'
      }
    ],
    meta: {
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  },
  {
    path: '/results',
    name: 'Results',
    component: () => import('../views/Results.vue'),
    meta: {
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  },
  {
    path: '/results/advanced-search',
    name: 'AdvancedSearch',
    component: () => import('../views/AdvancedSearch.vue'),
    meta: {
      breadcrumbs: [
        {
          title: 'Results',
          link: 'Results'
        }
      ],
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/Users.vue'),
    meta: {
      breadcrumbs: [
        {
          title: 'Users',
          link: 'Users'
        }
      ],
      middleware: [
        UserMustBeOwner
      ]
    }
  },
  {
    path: '/users/create',
    name: 'CreateUser',
    component: () => import('../views/CreateUser.vue'),
    meta: {
      breadcrumbTitle: 'Add a user',
      breadcrumbs: [
        {
          title: 'Users',
          link: 'Users'
        }
      ],
      middleware: [
        UserMustBeOwner
      ]
    }
  },
  {
    path: '/users/:id/edit',
    name: 'EditUser',
    component: () => import('../views/EditUser.vue'),
    props: true,
    meta: {
      breadcrumbTitle: 'Edit a user',
      breadcrumbs: [
        {
          title: 'Users',
          link: 'Users'
        }
      ],
      middleware: [
        UserMustBeOwner
      ]
    }
  },
  {
    path: '/organisations/:oId/practitioners/create',
    name: 'CreateHcp',
    component: () => import('../views/CreateHcp.vue'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          title: 'Organisations',
          link: 'Organisations'
        }
      ],
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  },
  {
    path: '/organisations/:oId/practitioners/:pId',
    name: 'ViewHcp',
    component: () => import('../views/ViewHcp.vue'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          title: 'Organisations',
          link: 'Organisations'
        }
      ],
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  },
  {
    path: '/organisations/:oId/practitioners/:pId/edit',
    name: 'EditHcp',
    component: () => import('../views/EditHcp.vue'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          title: 'Organisations',
          link: 'Organisations'
        }
      ],
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  },
  {
    path: '/collection-points',
    name: 'CollectionPoints',
    component: () => import('../views/CollectionPoints.vue'),
    meta: {
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  },
  {
    path: '/collection-points/create',
    name: 'CreateCollectionPoint',
    component: () => import('../views/CreateCollectionPoint.vue'),
    meta: {
      breadcrumbTitle: 'Add a collection point',
      breadcrumbs: [
        {
          title: 'Collection Points',
          link: 'CollectionPoints'
        }
      ],
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  },
  {
    path: '/collection-points/:id/edit',
    name: 'EditCollectionPoint',
    component: () => import('../views/EditCollectionPoint.vue'),
    meta: {
      breadcrumbTitle: 'Edit a collection point',
      breadcrumbs: [
        {
          title: 'Collection Points',
          link: 'CollectionPoints'
        }
      ],
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  },
  {
    path: '/results/tests/:oId/:tId',
    name: 'ViewTest',
    component: () => import('../views/ViewTest.vue'),
    meta: {
      breadcrumbs: [
        {
          title: 'Results',
          link: 'Results'
        }
      ],
      middleware: [
        UserMustBeAdminOrOwner
      ]
    }
  },
  {
    path: '/fulfilment/overview',
    name: 'FulfilmentOverview',
    component: () => import('../views/fulfilment/Overview.vue')
  },
  {
    path: '/fulfilment/open',
    name: 'FulfilmentOpen',
    component: () => import('../views/fulfilment/Open.vue')
  },
  {
    path: '/fulfilment/packing',
    name: 'FulfilmentPacking',
    component: () => import('../views/fulfilment/Packing.vue'),
    props: true
  },
  {
    path: '/fulfilment/dispatched',
    name: 'FulfilmentDispatched',
    component: () => import('../views/fulfilment/Dispatched.vue')
  },
  {
    path: '/fulfilment/qa',
    name: 'FulfilmentQa',
    component: () => import('../views/fulfilment/QA.vue')
  },
  {
    path: '/fulfilment/passedQa',
    name: 'FulfilmentPassedQa',
    component: () => import('../views/fulfilment/PassedQa.vue')
  },
  {
    path: '/fulfilment/futureOrders',
    name: 'FulfilmentFutureOrders',
    component: () => import('../views/fulfilment/FutureOrders.vue')
  },
  {
    path: '/fulfilment/cancelled',
    name: 'FulfilmentCancelled',
    component: () => import('../views/fulfilment/Cancelled.vue')
  },
  {
    path: '/fulfilment/orders/:id',
    name: 'ViewOrder',
    component: () => import('../views/ViewOrder.vue'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          title: 'Orders',
          link: 'FulfilmentOverview'
        }
      ]
    }
  },
  {
    path: '/fulfilment/advanced-search',
    name: 'FulfilmentSearch',
    component: () => import('../views/fulfilment/AdvancedSearch.vue')
  },
  {
    path: '/review-results',
    name: 'ReviewResults',
    component: () => import('../views/ReviewResults.vue'),
    meta: {
      middleware: [
        UserMustBeDomainAdmin
      ]
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
      return next()
    }

    if (to.name === 'ViewTest' && from.name === 'ViewTest') {
      store.dispatch('AppStore/SetShouldRefreshViewTestComponent', true)
    }

    store.dispatch('AddressFormStore/ClearState', true)

    const middleware = to.meta.middleware

    const context = {
      to,
      from,
      next,
      router,
      store
    }

    return middleware[0]({ ...context, next: MiddlewarePipeline(context, middleware, 1) })
})

export default router
