<template>
    <div class="sidebar d-none d-lg-block">
        <div class="main-navigation">
            <ul>
                <li v-if="userIsAdminOrOwner" @click="$router.push({name: 'Dashboard'})" :class="{active : $route.name == 'Dashboard'}">Dashboard</li>
                <li v-if="userIsDomainAdmin" @click="$router.push({name: 'Domains'})" :class="{active : $route.name == 'Domains'}">Domains</li>
                <li v-if="userIsAdminOrOwner" @click="$router.push({name: 'Organisations'})" :class="{active : $route.name == 'Organisations'}">Organisations</li>
                <li v-if="userIsAdminOrOwner" @click="getStatusCountsAction(); $router.push({name: 'Results'})" :class="{active : $route.name == 'Results'}">Results</li>
                <li v-if="userIsDomainAdmin" @click="getStatusCountsAction(); $router.push({name: 'ReviewResults'})" :class="{active : $route.name == 'ReviewResults'}" class="d-flex justify-content-between align-items-center">Review Results<div><span class="badge badge-status">{{ resultsInReviewCount ?? '0' }}</span></div></li>
                <li v-if="userIsOwner" @click="$router.push({name: 'Users'})" :class="{active : $route.name == 'Users'}">Users</li>
                <!-- Commented out until Collection Points are required again -->
                <!-- <li v-if="userIsAdminOrOwner" @click="$router.push({name: 'CollectionPoints'})" :class="{active : $route.name == 'CollectionPoints'}">Collection Points</li> -->
                <li @click="fulfilmentMenuOpen = !fulfilmentMenuOpen">
                    Fulfilment
                    <span v-if="loading" class="btn-loading fulfilment-stats"></span>
                    <span v-else class="arrow-right" :class="{'arrow-active' : fulfilmentMenuOpen}"></span>
                </li>
                <div v-if="fulfilmentMenuOpen">
                    <li @click="getStatusCountsAction(); $router.push({name: 'FulfilmentOverview'})" :class="{active : $route.name == 'FulfilmentOverview'}">Overview</li>
                    <li @click="$router.push({name: 'FulfilmentOpen'})" class="d-flex justify-content-between align-items-center" :class="{active : $route.name == 'FulfilmentOpen'}">
                        <div><span class="status-circle open"></span> Open</div>
                        <div><span class="badge badge-status">{{ statusCounts.OPEN }}</span></div>
                    </li>
                    <li @click="getStatusCountsAction(); $router.push({name: 'FulfilmentPacking'})" class="d-flex justify-content-between align-items-center" :class="{active : $route.name == 'FulfilmentPacking'}">
                        <div><span class="status-circle packing"></span> Packing</div>
                        <div><span class="badge badge-status">{{ statusCounts.PACKING ? statusCounts.PACKING : '0' }}</span></div>
                    </li>
                    <li @click="getStatusCountsAction(); $router.push({name: 'FulfilmentQa'})" class="d-flex justify-content-between align-items-center" :class="{active : $route.name == 'FulfilmentQa'}">
                        <div><span class="status-circle qa"></span> QA</div>
                        <div><span class="badge badge-status">{{ statusCounts.QA ? statusCounts.QA : '0' }}</span></div>
                    </li>
                    <li @click="getStatusCountsAction(); $router.push({name: 'FulfilmentPassedQa'})" class="d-flex justify-content-between align-items-center" :class="{active : $route.name == 'FulfilmentPassedQa'}">
                        <div><span class="status-circle passed-qa"></span> Passed QA</div>
                        <div><span class="badge badge-status">{{ statusCounts.PASSED_QA ? statusCounts.PASSED_QA : '0' }}</span></div>
                    </li>
                    <li @click="getStatusCountsAction(); $router.push({name: 'FulfilmentDispatched'})" class="d-flex justify-content-between align-items-center" :class="{active : $route.name == 'FulfilmentDispatched'}">
                        <div><span class="status-circle dispatched"></span> Dispatched</div>
                        <div><span class="badge badge-status">{{ statusCounts.DISPATCHED ? statusCounts.DISPATCHED : '0' }}</span></div>
                    </li>
                    <li @click="getStatusCountsAction(); $router.push({name: 'FulfilmentCancelled'})" class="d-flex justify-content-between align-items-center" :class="{active : $route.name == 'FulfilmentCancelled'}">
                        <div><span class="status-circle cancelled"></span> Cancelled</div>
                        <div><span class="badge badge-status">{{ statusCounts.CANCELLED }}</span></div>
                    </li>
                    <li @click="getStatusCountsAction(); $router.push({name: 'FulfilmentFutureOrders'})" class="d-flex justify-content-between align-items-center" :class="{active : $route.name == 'FulfilmentFutureOrders'}">
                        <div><span class="status-circle future"></span> Future Orders</div>
                        <div><span class="badge badge-status">{{ statusCounts.FUTURE }}</span></div>
                    </li>
                    <li @click="getStatusCountsAction(); $router.push({name: 'FulfilmentSearch'})" class="d-flex align-items-center" :class="{active : $route.name == 'FulfilmentSearch'}"><img class="mr-1" src="@/assets/images/icons/search-icon.svg" width="12" /> Search</li>
                </div>
            </ul>
        </div>

        <ul class="fixed-to-bottom">
            <li><a target="_blank" href="https://support.gttathome.com/en/kb-tickets/new">Report issue</a></li>
            <li><a target="_blank" href="https://support.gttathome.com/en">Help</a></li>
        </ul>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    name: 'SidebarComponent',

    data () {
        return {
            loading: true,
            fulfilmentMenuOpen: false
        }
    },

    async mounted () {
        if (this.userIsFulfilment) this.fulfilmentMenuOpen = true
        try {
            await this.getStatusCountsAction()
        } catch {
            this.setNotification({
                type: 'error',
                title: 'Error',
                message: 'Something went wrong while fetching the status counts. Please try again.'
            })
        } finally {
            this.loading = false
        }
    },

    computed: {
        ...mapGetters({
            userIsAdminOrOwner: 'UserStore/GetIsUserAdminOrOwner',
            userIsOwner: 'UserStore/GetIsUserOwner',
            userIsFulfilment: 'UserStore/GetIsUserFulfilment',
            userIsDomainAdmin: 'UserStore/GetIsDomainAdmin',
            statusCounts: 'FulfilmentStatusCountsStore/GetStatusCounts',
            resultsInReviewCount: 'FulfilmentStatusCountsStore/GetResultsInReviewCount'
        })
    },

    methods: {
        ...mapActions({
            getStatusCountsAction: 'FulfilmentStatusCountsStore/GetStatusCounts',
            setNotification: 'NotificationStore/SetNotification'
        })
    }
}
</script>
