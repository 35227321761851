import axios from 'axios'
import RoleType from '../enums/RoleType'
import router from '../router'
export default {
    namespaced: true,

    state: {
        isAuthenticated: false,
        appInitLoaded: false,
        accessToken: null,
        roleType: null,
        authDetails: {},
        userDetails: {},
        userDetailsLoaded: false,
        userScopes: {},
        userDomains: [],
        claims: [],
        currentDomain: null,
        userAccount: null
    },

    getters: {
        GetIsUserAuthenticated (state) {
            return state.isAuthenticated
        },

        GetAppInitLoaded (state) {
            return state.appInitLoaded
        },

        GetUserInitials (state) {
            return `${state.userDetails.firstName[0]}${state.userDetails.lastName[0]}`
        },

        GetUserAuthDetails (state) {
            return state.userAuthDetails
        },

        GetUserOrganisationId (state) {
            return state.organisationId
        },

        GetUserDetails (state) {
            return state.userDetails
        },

        GetUserId (state) {
            return state.userDetails.id
        },

        GetUserDetailsLoaded (state) {
            return state.userDetailsLoaded
        },

        GetUserRoleType (state) {
            return state.roleType
        },

        GetUserRole (state) {
            return state.userDetails.role
        },

        GetIsUserOwner (state) {
            return state.userDetails.role === RoleType.OWNER || state.roleType === 'OWNER'
        },

        GetIsUserAdminOrOwner (state) {
            return state.userDetails.role === RoleType.OWNER || state.userDetails.role === RoleType.ADMIN || state.roleType === 'OWNER' || state.roleType === 'ADMIN'
        },

        GetIsUserFulfilment (state) {
            return state.userDetails.role === RoleType.FULFILMENT || state.roleType === 'FULFILMENT'
        },

        GetUserFullName (state) {
            return `${state.userDetails.firstName} ${state.userDetails.lastName}`
        },

        GetUserTitle (state) {
            return state.userDetails.title
        },

        GetIsDomainAdmin (state) {
            return state.authDetails.idTokenClaims.extension_DomainManager === 'true'
        },

        GetUserDomains (state) {
            return state.userDomains
        },

        GetUserCurrentDomain (state) {
            return state.currentDomain
        },

        GetAccessToken (state) {
            return state.accessToken
        },

        GetIsUserDomainAssigner (state) {
            return state.claims.extension_DomainManager === 'true' || state.claims.extension_DomainAssigner === 'true'
        },

        GetUserAccount (state) {
            return state.userAccount
        }
    },

    mutations: {
        SET_USER_IS_AUTHENTICATED (state, data) {
            state.isAuthenticated = data
        },
        SET_ACCESS_TOKEN (state, data) {
            state.accessToken = data
        },
        PUSH_USER_DETAILS (state, data) {
            state.userDetails = data
            state.title = data.title
            state.userDetailsLoaded = true
        },
        SET_APP_INIT_LOADED (state) {
            state.appInitLoaded = true
        },
        SET_AUTH_DETAILS (state, data) {
            state.authDetails = data
        },
        SET_USER_NAME (state, data) {
            state.userDetails.firstName = data.firstName
            state.userDetails.surname = data.surname
        },
        SET_CLAIMS (state, data) {
            state.claims = data
        },
        SET_USER_ROLE_TYPE (state, data) {
            state.roleType = data
        },
        SIGNOUT (state) {
            state.isAuthenticated = false
            state.accessToken = null
            state.organisationId = null
            state.roleType = null
            state.userAuthDetails = {}
            state.authDetails = {}
            state.userDetails = {}
            state.userDetailsLoaded = false
            state.userScopes = {}
            state.userDomains = []
            state.claims = []
        },
        SET_USER_SCOPES (state, data) {
            state.userScopes = data
        },
        SET_USER_DOMAINS (state, data) {
            state.userDomains = data
        },
        SET_USER_CURRENT_DOMAIN (state, data) {
            state.currentDomain = data
        },
        SET_USER_ACCOUNT (state, data) {
            state.userAccount = data
        }
    },

    actions: {
        async GetUserDetails ({ commit }) {
            try {
                const response = await axios.get(`${process.env.VUE_APP_AXIOS_ADMIN_BASE_URL}/api/v1/admins/me`)

                commit('PUSH_USER_DETAILS', response.data)
            } catch (err) {
                throw new Error(err)
            }
        },

        async UpdateUserDetails ({ commit }, data) {
            try {
                const response = await axios.patch(`${process.env.VUE_APP_AXIOS_ADMIN_BASE_URL}/api/v1/admins/me`, data.userDetails)

                commit('PUSH_USER_DETAILS', response.data)
            } catch (err) {
                throw new Error(err)
            }
        },

        SetUserName ({ commit }, data) {
            commit('SET_USER_NAME', data)
        },

        SetAppInitLoaded ({ commit }) {
            commit('SET_APP_INIT_LOADED')
        },

        async Signout ({ commit }) {
            commit('SIGNOUT')

            await router.app.$msal.signOut()
        },

        SetCurrentDomainId ({ commit }, data) {
            localStorage.setItem('currentDigosticsDomain', data)
            commit('SET_USER_CURRENT_DOMAIN', data)
        }
    }
}
